.postlist {
    width: 80%;
    margin: auto;
    text-align: center;
}

.post {
    width: 40%;
    margin: auto;
}

.page-content {
    width: 80%;
    margin: auto;
    text-align: center;
}

.notfound {
    text-align: center;
}

/* Prayer Partners Page Styling */
.prayer-partners-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}


/* Center the rotation countdown */
.rotation-countdown-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.rotation-countdown {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 600px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.rotation-countdown h3 {
  font-size: 1.5rem;
}

/* Center the "Current Prayer Partners" heading */
.partners-heading {
    text-align: center;
    margin: 30px 0;
    font-size: 2rem;
}

/* Grid layout for the partner pairs */
.partners-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.partner-pair {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.partner-pair h3 {
    margin-top: 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.partner-pair ul {
    padding-left: 20px;
    margin: 0;
}

.partner-pair li {
    margin-bottom: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .partners-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    
    .page-title {
        font-size: 2rem;
    }
    
    .partners-heading {
        font-size: 1.5rem;
    }
}
/* Add these styles to your pages.css file */

.schedule-toggle {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 1rem;
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #357ab8;
}

.full-schedule {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.weeks-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.week-schedule {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
}

.week-pairs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.schedule-pair {
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.schedule-pair h4 {
  margin-top: 0;
  font-size: 14px;
  color: #555;
}

.schedule-pair ul {
  padding-left: 15px;
  margin-bottom: 0;
}

.schedule-pair li {
  font-size: 14px;
}

.date-range {
  font-size: 14px;
  color: #666;
  margin-top: -10px;
  margin-bottom: 10px;
  font-style: italic;
}

.copy-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}